<template>
  <div>
    <heads></heads>
    <div
      class="cen"
      :style="{
        backgroundImage: 'url(' + require('@/assets/' + backgroud) + ')',
      }"
    >
      <div class="cen_box">
        <div class="cen-com cen-com2">
          <div class="cen_com_top">
            <div
              class="cen_top_one"
              v-for="(item, index) in theory.slice(0, 16).reverse()"
              :key="index"
            >
              <router-link
                :to="{
                  path: pathUrl,
                  query: { id: item.id },
                }"
                class="items"
                >︽{{ item.name }} ︾</router-link
              >
            </div>
          </div>
          <div class="cen_com_bot">
            <div
              v-for="(item, index) in theory.slice(16, 25).reverse()"
              :key="index"
            >
              <router-link
                :to="{
                  path: pathUrl,
                  query: { id: item.id },
                }"
                class="items"
                >︽ {{ item.name }} ︾</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import "@css/ico.css";
import "@css/foot.css";
import heads from "@components/head.vue";
import foot from "@components/foot.vue";
import { gettheoryCourses } from "@api/public";
export default {
  components: {
    heads,
    foot,
  },
  props: {},
  data() {
    return {
      theory: [],
      backgroud: "",
      pathUrl: "",
    };
  },
  mounted: function () {
    let that = this;
    gettheoryCourses().then(function (res) {
      that.$set(that, "theory", res.data);
      console.log(that.theory);
    });
  },
  created: function () {
    this.pathUrl = this.$route.meta.path;
    this.backgroud = this.$route.meta.background;
    document.title = this.$route.meta.title;
  },
};
</script>
  
<style>
.cen {
  width: 100%;
  min-width: 1484px;
  height: 1032px;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}
.cen_box {
  width: 1484px;
  height: 1032px;
  margin: 0 auto;
  position: relative;
}
.cen-com {
  width: 800px;
  height: 480px;
  position: absolute;
  top: 310px;
  left: 610px;
  display: flex;
}
.cen-com2 {
  flex-direction: column;
  position: relative;
}
.cen_com_top {
  width: 400px;
  height: 260px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: -140px;
  top: -30px;
}
.cen_com_bot {
  width: 500px;
  height: 170px;
  margin-left: 130px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 230px;
}
.items {
  width: 12px;
  color: #000;
  text-decoration: none;
  display: block;
  font-size: 22px;
  margin: 0 15px;
  font-family: "kaiti";
  font-weight: bold;
  writing-mode: vertical-lr;
}
</style>
